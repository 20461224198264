<template>
    <layout>
        <div class="container mt30">
            <div class="infomation-list">
                <div class="infomation-tab">
                    <div class="info-bg">
                        <div class="info-bg-top">
                            <img class="mt15 info-bg-img" src="../assets/images/hot-topic.png" alt=""></img>
                        </div>
                    </div>
                    <a-tabs class="info-tab-pane" v-model:activeKey="activeKey" :tab-position="tabPosition"
                        @change="handleTabChangeEvent">
                        <a-tab-pane key="115" tab="会议动态">
                            <div class="flex">
                                <a-spin :spinning="loading" tip="Loading...">
                                    <div class="meeting-report">
                                        <div v-for="(item, index) in infoListData[0]" :key="index" class="hot-topic-box"
                                            :class="{ 'mt20': index != 0 }">
                                            <hot-topic-item path="/infomation-detail" type="115" :item="item" class="bb">
                                            </hot-topic-item>
                                        </div>
                                        <a-empty v-if="!infoListData[0].length && !loading" />
                                        <div class="t-c mt30" v-if="infoParams[0].listCount > 0">
                                            <a-pagination :total="infoParams[0].listCount" v-model:current="infoParams[0].params.page"
                                                :page-size="infoParams[0].params.limit" :show-size-changer="false" show-quick-jumper
                                                :hideOnSinglePage="true"
                                                @change="handlePageChangeEvent('0', $event)" />
                                        </div>
                                    </div>
                                </a-spin>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="116" tab="学术资讯">
                            <div class="flex">
                                <a-spin :spinning="loading" tip="Loading...">
                                    <div class="meeting-report">
                                        <div v-for="(item, index) in infoListData[1]" :key="index" class="hot-topic-box"
                                            :class="{ 'mt20': index != 0 }">
                                            <hot-topic-item path="/infomation-detail" type="116" :item="item" class="bb">
                                            </hot-topic-item>
                                        </div>
                                        <a-empty v-if="!infoListData[1].length && !loading" />
                                        <div class="t-c mt30" v-if="infoParams[1].listCount > 0">
                                            <a-pagination :total="infoParams[1].listCount" v-model:current="infoParams[1].params.page"
                                                :page-size="infoParams[1].params.limit" :show-size-changer="false" show-quick-jumper
                                                :hideOnSinglePage="true"
                                                @change="handlePageChangeEvent('1', $event)" />
                                        </div>
                                    </div>
                                </a-spin>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="6" tab="大咖访谈">
                            <div class="flex">
                                <a-spin :spinning="loading" tip="Loading...">
                                    <div class="meeting-report">
                                        <div v-for="(item, index) in infoListData[2]" :key="index" class="hot-topic-box"
                                            :class="{ 'mt20': index != 0 }">
                                            <hot-topic-item path="/infomation-detail" type="6" :item="item" class="bb">
                                            </hot-topic-item>
                                        </div>
                                        <a-empty v-if="!infoListData[2].length && !loading" />
                                        <div class="t-c mt30" v-if="infoParams[2].listCount > 0">
                                            <a-pagination :total="infoParams[2].listCount" v-model:current="infoParams[2].params.page"
                                                :page-size="infoParams[2].params.limit" show-quick-jumper
                                                :hideOnSinglePage="true"
                                                @change="handlePageChangeEvent('2', $event)" />
                                        </div>
                                    </div>
                                </a-spin>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="招聘新闻">
                            <div class="flex">
                                <a-spin :spinning="loading" tip="Loading...">
                                    <div class="meeting-report">
                                        <div v-for="(item, index) in infoListData[3]" :key="index" class="hot-topic-box"
                                            :class="{ 'mt20': index != 0 }">
                                            <hot-topic-item path="/infomation-detail" type="3" :item="item" class="bb">
                                            </hot-topic-item>
                                        </div>
                                        <a-empty v-if="!infoListData[3].length && !loading" />
                                        <div class="t-c mt30" v-if="infoParams[3].listCount > 0">
                                            <a-pagination :total="infoParams[3].listCount" v-model:current="infoParams[3].params.page"
                                                :page-size="infoParams[3].params.limit" show-quick-jumper :hideOnSinglePage="true"
                                                @change="handlePageChangeEvent('3', $event)" />
                                        </div>
                                    </div>
                                </a-spin>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="4" tab="期刊新闻">
                            <div class="flex">
                                <a-spin :spinning="loading" tip="Loading...">
                                    <div class="meeting-report">
                                        <div v-for="(item, index) in infoListData[4]" :key="index" class="hot-topic-box"
                                            :class="{ 'mt20': index != 0 }">
                                            <hot-topic-item path="/infomation-detail" type="4" :item="item" class="bb">
                                            </hot-topic-item>
                                        </div>
                                        <a-empty v-if="!infoListData[4].length && !loading" />
                                        <div class="t-c mt30" v-if="infoParams[4].listCount > 0">
                                            <a-pagination :total="infoParams[4].listCount" v-model:current="infoParams[4].params.page"
                                                :page-size="infoParams[4].params.limit" show-quick-jumper :hideOnSinglePage="true"
                                                @change="handlePageChangeEvent('4', $event)" />
                                        </div>
                                    </div>
                                </a-spin>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="7" tab="指南解读">
                            <div class="flex">
                                <a-spin :spinning="loading" tip="Loading...">
                                    <div class="meeting-report">
                                        <div v-for="(item, index) in infoListData[5]" :key="index" class="hot-topic-box"
                                            :class="{ 'mt20': index != 0 }">
                                            <hot-topic-item path="/infomation-detail" type="7" :item="item" class="bb">
                                            </hot-topic-item>
                                        </div>
                                        <a-empty v-if="!infoListData[5].length && !loading" />
                                        <div class="t-c mt30" v-if="infoParams[5].listCount > 0">
                                            <a-pagination :total="infoParams[5].listCount" v-model:current="infoParams[5].params.page"
                                                :page-size="infoParams[5].params.limit" show-quick-jumper :hideOnSinglePage="true"
                                                @change="handlePageChangeEvent('5', $event)" />
                                        </div>
                                    </div>
                                </a-spin>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                    <div style="width: 398px;float: right;" class="ml30 topic-right">
                        <layout-right :config="layoutRightConfig"></layout-right>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index.vue';
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title.vue';
import acrossMediaItem from '@/components/across-media-item.vue';
import liveItem from '@/components/live-item.vue';
import verticalMediaItem from '@/components/vertical-media-item.vue';
import hotTopicItem from '@/components/hot-topic-item.vue';
import { mapState } from 'vuex';
import storage from 'store';
export default {
    name: 'InfomationList',
    data() {
        return {
            loading: true,
            tabPosition: 'left',
            fixed:false,
            activeKey: '116',
            infoParams:[
                // 会议动态
                {
                    params:{limit: 8,type: '115',page: 1,operate_id: ''},
                    listCount: 0,
                },
                // 学术资讯
                {
                    params:{limit: 8,type: '116',page: 1,operate_id: ''},
                    listCount: 0,
                },
                // 大咖访谈
                {
                    params:{limit: 8,type: '6',page: 1,operate_id: '',is_expert: 1,},
                    listCount: 0,
                },
                // 招聘新闻
                {
                    params:{limit: 8,type: '3',page: 1,operate_id: ''},
                    listCount: 0,
                },
                // 期刊新闻
                {
                    params:{limit: 8,type: '4',page: 1,operate_id: ''},
                    listCount: 0,
                },
                // 指南解读
                {
                    params:{limit: 8,type: '7',page: 1,operate_id: ''},
                    listCount: 0,
                },
                
            ],
            infoListData:[[],[],[],[],[],[]],
            layoutRightConfig: {
                // 本月会议
                meeting: {
                    show: true, // 是否显示
                    title: '本月会议', // 标题
                    methods: 'get',
                    moreLink: '/meeting-list?year=' + (new Date()).getFullYear() + '&month=' + ((new Date()).getMonth() + 1),
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 6,
                        is_now_year_meeting: 1,
                        subject_ids: storage.get('subjectIds') || ''
                    },   // 参数条件
                },
                correlationLive: { //推荐直播
                    show: true, // 是否显示
                    methods: 'get',
                    title: '推荐直播', // 标题
                    data: {
                        tj_token: storage.get('tid'),
                        page: 1,
                        limit: 3,
                        subject_ids: storage.get('subjectIds') || ''
                    },   // 参数条件
                },
                // 推荐视频
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '最新回放', // 标题
                    data: {
                        page: 1,
                        PageSize: 4
                    },   // 参数条件
                },
            },
        }
    },
    components: {
        layout,
        layoutRight,
        commonTitle,
        acrossMediaItem,
        liveItem,
        verticalMediaItem,
        hotTopicItem
    },
    watch: {
        $route: {
            handler() {
                let { index } = this.$route.query
                this.activeKey = index;
                if(index){
                    this.infoParams.forEach((items,indexs)=>{
                        if(index == items.params.type){
                            if (this?.infoListData[indexs]?.length) return false;
                            this.getInfoList(indexs)
                        }
                    })
                }
            },
            immediate: true
        },
    },
    computed: {
        ...mapState(['userInfo'])
    },
    created() {
        this.getInfoList(0)
    },
    //当引入keep-alive的时候，页面第一次进入，钩子的触发顺序created-> mounted-> activated，退出时触发deactivated。
    //当再次进入（前进或者后退）时，只触发activated。
    activated() {

    },
    methods: {
        getInfoList(index){
            this.loading = true
            let { operate_subject } = this.userInfo
            
            this.infoParams[index].params.operate_id = operate_subject
            this.request.post('GetNews', this.infoParams[index].params).then(res => {
                this.infoParams[index].listCount = parseInt(res.data.count)
                this.infoListData[index] = res.data.list
                this.loading = false
            })
        },
        // 页码切换
        handlePageChangeEvent(key, page) {
            window.scrollTo(0,0);
            this.infoParams[key].params.page = page
            this.getInfoList(key)
        },
        // tab切换
        handleTabChangeEvent(e) {
            this.$router.push({
                path: '/infomation-list?index=' + e
            })
        },
    },
    mounted() {
        // 取屏幕宽度的1/2或1/3适配移动端排列
        let clientWidth = document.body.clientWidth
        this.tabPosition = clientWidth < 768 ? 'top' : 'left';
    },
}
</script>
<style lang="less" scoped>
@media screen and (min-width:769px) {
    .infomation-list {
        .infomation-tab {
            width: 100%;
            position: relative;

            .info-bg {
                width: 146px;
                height: 350px;
                background: #fbfcfd;
                // background: url(../assets/images/infomation-tab.png) 0 0;
                // background-size: 100%;
                position: absolute;
                left: 0;
                display: inline;
                top: 0;
                .info-bg-top{
                    width: 100%;
                    height: 50px;
                    background: #1e51c9;
                    border-top-left-radius: 25px;
                }
            }

            .info-bg-img {
                width: 88px;
                height: 19px;
                position: absolute;
                left: 29px;
                top: 0;
            }

            .info-tab-pane {
                width: 770px;
                float: left;
                position: relative;
                min-height: 500px;

                /deep/.ant-tabs-nav .ant-tabs-tab-active {
                    color: #1e51c9;
                    font-weight: bold;
                }

                /deep/ .ant-tabs-bar {
                    width: 146px !important;
                    // height: 200px;
                    border: 0;
                    margin-top: 58px;
                }

                /deep/.ant-tabs-content {
                    border: 0 !important;
                }

                /deep/.ant-tabs-tab {
                    height: 30px;
                    text-align: center;
                    font-size: 18px;
                }

                /deep/.ant-tabs-ink-bar {
                    left: 3px;
                    height: 17px !important;
                    align-items: center;
                    margin-top: 14px;
                    border-radius: 0 100px 100px 0;
                }
                /deep/.ant-tabs-nav-container-scrolling{
                    padding: 0;
                }
                /deep/.ant-tabs-tab-arrow-show{
                    display: none;
                }
            }
        }
    }

    .meeting-report {
        width: 601px;
        min-height: 500px;
        .meeting-title {
            .meeting-title-first {
                color: #253e6d;
            }
        }

        .report-img {
            height: 105px;

            .report-inner-img {
                width: 187px;
                height: 105px;
            }

            .report-icon {
                width: 20px;
                height: 20px;
            }
        }

        .bb {
            border-bottom: 1px solid #d8d8d8;
        }

        .hot-topic-box:last-child .bb {
            border-bottom: 0;
        }
    }

    .report-dispatches {
        position: absolute;
        top: 247px;
        width: 144px;
        height: 52px;
        background: linear-gradient(to right, #5f96e6, #1e51c9);
        border-radius: 50px;
        box-shadow: 0 1px 2px 0 rgba(30, 81, 201, 0.15);

        .img {
            width: 29px;
            height: 29px;
        }
    }

    .report-teacher {
        position: absolute;
        top: 306px;
        width: 144px;
        height: 52px;
        background: linear-gradient(to right, #f7c86d, #ffa45d);
        border-radius: 50px;
        box-shadow: 0 1px 2px 0 rgba(255, 164, 93, 0.15);

        .img {
            width: 28px;
            height: 31px;
        }
    }
}

@media screen and (max-width:768px) {
    .container{
        display: block;
        padding: 0 10px;
        margin-top:0;
    }
    .infomation-list {
        .info-bg,
        .info-bg-img {
            display: none;
        }
        .topic-right{
            width: 100%!important;
            margin-top: 30px;
        }
        /deep/.ant-tabs-bar{
            width: calc(100% - 20px);
            position: fixed;
            background: #fff;
            z-index: 999;
        }
        /deep/.ant-spin-nested-loading {
            width: 100%;
        }
        /deep/.ant-tabs-nav .ant-tabs-tab-active {
            color: #1e51c9;
            font-weight: bold;
        }
        /deep/.ant-tabs-ink-bar{
            background: #1e51c9;
        }
        /deep/.ant-tabs-tab-arrow-show{
            display: none;
        }
        /deep/.ant-tabs-nav-scroll{
            overflow: auto;
        }
        /deep/.ant-tabs-nav-container-scrolling{
            padding: 0;
        }
    }

    .meeting-report {
        margin-top: 60px;
        
    }
}
</style>
